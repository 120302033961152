@tailwind base;
@tailwind components;
@tailwind utilities;
@import "react-toastify/dist/ReactToastify.css";

body {
  min-height: 100vh;
}

.spinner {
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Common Button styles */
.btn-primary {
  @apply bg-creately-primary-dark rounded text-white items-center flex px-4 md:px-3 py-2 shadow-md hover:bg-creately-primary;
}

.nav-link {
  @apply p-2 my-2 flex space-x-2 rounded-md items-center text-gray-500 hover:text-creately-primary-dark;
}

.nav-link--active {
  @apply bg-creately-primary-dark bg-opacity-20 text-creately-primary-dark;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  width: 100%;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.pagination-container {
  display: flex;
  list-style-type: none;
}

.pagination-container .pagination-item {
  padding: 0 12px;
  height: 32px;
  text-align: center;
  margin: auto 4px;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  box-sizing: border-box;
  align-items: center;
  letter-spacing: 0.01071em;
  border-radius: 16px;
  line-height: 1.43;
  font-size: 13px;
  min-width: 32px;
}

.pagination-container .pagination-item.dots:hover {
  background-color: transparent;
  cursor: default;
}

.pagination-container .pagination-item:hover {
  background-color: rgba(0, 0, 0, 0.04);
  cursor: pointer;
}

.pagination-container .pagination-item.selected {
  background-color: rgba(0, 0, 0, 0.08);
}

.pagination-container .pagination-item .arrow::before {
  position: relative;
  content: '';
  display: inline-block;
  width: 0.4em;
  height: 0.4em;
  border-right: 0.12em solid rgba(0, 0, 0, 0.87);
  border-top: 0.12em solid rgba(0, 0, 0, 0.87);
}

.pagination-container .pagination-item .arrow.left {
  transform: rotate(-135deg) translate(-50%);
}

.pagination-container .pagination-item .arrow.right {
  transform: rotate(45deg);
}

.pagination-container .pagination-item.disabled {
  pointer-events: none;
}

.pagination-container .pagination-item.disabled .arrow::before {
  border-right: 0.12em solid rgba(0, 0, 0, 0.43);
  border-top: 0.12em solid rgba(0, 0, 0, 0.43);
}

.pagination-container .pagination-item.disabled:hover {
  background-color: transparent;
  cursor: default;
}

.swiper-button-next, .swiper-button-prev {
  margin-top: auto !important;
}

:root {
  --toastify-toast-width: 460px;
  --toastify-color-dark: #272929;
  --toastify-color-info: #2988c8;
  --toastify-color-success: #1ca821;
  --toastify-color-warning: #FFC648;
  --toastify-color-error: #f36c67;
  --swiper-navigation-size: 20px !important;
  --swiper-theme-color: #40b29e !important;
}

.CodeMirror {
  font-family: monaco, Consolas, Menlo, Courier, monospace;
  font-size: 11px;
  line-height: 1.5em;
}
